<template>
  <div id="fontsize-container" :class='fontsize'>


  <b-button-group class="mx-1">
    <b-button @click='$store.commit("decreaseSize")'>
      A&nbsp;-


    </b-button>
    <b-button @click='$store.commit("reset")'>
      A
    </b-button>
    <b-button @click='$store.commit("increaseSize")'>
      A&nbsp;+
    </b-button>
  </b-button-group>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'FontSize',
    data() {
      return {

      }
    },
    mounted() {      
    },

    computed: {
      fontsize() {
        return this.$store.getters.size
      }
    }
}
</script>

<style scoped>
  #fontsize-container {
    position: fixed;
    z-index: 5;
    opacity: 0.9;
    bottom:  70px;
    right:  25px;
    font-family: 'Frutiger Neue LT Book', sans-serif;
    font-size: 16px;
  }

  @media(min-width: 420px){
    #fontsize-container {
      position: fixed;
      z-index: 5;
      opacity: 0.9;
      bottom:  55px;
      right:  25px;
      font-family: 'Frutiger Neue LT Book', sans-serif;
      font-size: 16px;
    }      
  }
  #fontsize-container button{
    width: 40px;
    height:  35px;
    padding:  0;
  }

  .btn-group > .btn:not(:last-child){
    border-right: 1px solid #CCC;
  }
  .btn-group > .btn:not(:first-child){
    border-left: 1px solid #CCC;
  }
</style>