import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/covid',
        name: 'Covid',
        component: () => import('../views/pages/Covid.vue')
    },
    {
        path: '/our-organisation',
        name: 'OurOrganisation',
        component: () => import('../views/pages/OurOrganisation.vue')
    },
    {
        path: '/our-leadership',
        name: 'OurLeadership',
        component: () => import('../views/pages/OurLeadership.vue')
    },
    {
        path: '/contributors',
        name: 'Contributors',
        component: () => import('../views/pages/Contributors.vue')
    },
    {
        path: '/one-aic-family',
        name: 'OneAICFamily',
        component: () => import('../views/pages/OneAICFamily.vue')
    },
    {
        path: '/looking-ahead',
        name: 'LookingAhead',
        component: () => import('../views/pages/LookingAhead.vue')
    },
    {
        path: '/support-public',
        name: 'SupportPublic',
        component: () => import('../views/pages/SupportPublic.vue')
    },
    {
        path: '/support-seniors',
        name: 'SupportSeniors',
        component: () => import('../views/pages/SupportSeniors.vue')
    },
    {
        path: '/support-partners',
        name: 'SupportPartners',
        component: () => import('../views/pages/SupportPartners.vue')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/misc/chairmans-message',
        name: 'Chairmans-message',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chairmans message" */ '../views/misc/Chairmans-message.vue')
    },
    {
        path: '/misc/ceo-message',
        name: 'Ceo-message',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chairmans message" */ '../views/misc/Ceo-message.vue')
    },
    {
        path: '/misc/management',
        name: 'management',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "management" */ '../views/misc/Management.vue')
    },

    {
        path: '/iachievements',
        name: 'Iachievements',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "iachivements/home" */ '../views/iachievements/home.vue')
    }     
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if(to.hash) {
              
              /*const element = document.getElementById(to.hash.replace(/#/, ''))

              var topOffset = element.getBoundingClientRect().top;
              var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
              var adjust_ = 0;

              if(document.documentElement.clientWidth > 760) adjust_ = 40;
              else adjust_ = 60;
              var topOffset = topOffset + scrollTop - adjust_;

              window.scrollTo({top: topOffset, behavior: "smooth"})
              */
                  return new Promise((resolve, reject) => {
                    setTimeout(() => {
                    const element = document.getElementById(to.hash.replace(/#/, ''))

                      var topOffset = element.getBoundingClientRect().top;
                      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                      var adjust_ = 0;

                      if(document.documentElement.clientWidth > 760) adjust_ = 10;
                      else adjust_ = 60;
                      var topOffset = topOffset + scrollTop - adjust_;

                      window.scrollTo({top: topOffset, behavior: "smooth"})                             
                    }, 500)
                  })
                /*return { 
                    selector: to.hash,
                    behavior: 'smooth'
                };
                */

        }
        else if (savedPosition) {
            return savedPosition
        }
        window.scrollTo(0,0);
        return false
    }  
})

export default router
