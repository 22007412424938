<template>
    <section class="tab-2 row w-100 page-about">
        <div class="col-md-3 video" v-b-modal.video-whiteboard>
            <div class="text-container" >
                <h4 >Our People </h4>
                <div class="img-container">
                    <img src="@/assets/home/new-layout/ourpeopleicon.png" alt="">
                </div>
                <h5 class="mt-2">Watch Here</h5>
            </div>
        </div>
        <div class="col-md-3 position-relative">
            <router-link to='/our-organisation'>
            <div class="text-container">
                <h4>Our Organisation</h4>
                <div class="img-container">
                    <img src="@/assets/home/organisation.png" alt="">
                </div>
            </div>
            </router-link>
        </div>
        <div class="col-md-3 position-relative">
            <router-link to='/our-leadership'>
            <div class="text-container">
                <h4>Our Leadership</h4>
                <div class="img-container">
                    <img src="@/assets/home/leadership.png" alt="">
                </div>
            </div>
            </router-link>
        </div>
        <div class="col-md-3 position-relative">
            <router-link to='/contributors'>
            
            <div class="text-container">
                <h4>Our Key Committees</h4>
                <div class="img-container">
                    <img src="@/assets/home/contributors.png" alt="">
                </div>
            </div>
            </router-link>
        </div>

        <b-modal size="lg" centered hide-header hide-footer 
            variant='light' 
            id="video-whiteboard" 
            ref='video-whiteboard'
            footer-class='modal-footer'
            >
            <font-awesome-icon @click='hideModal' :icon="['fas', 'times']" class='modal-close'/>
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/BHEUUemdaQM" title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </b-modal>
    </section>
</template>

<script>
export default {
  name: 'About',
  methods: {
    hideModal() {
            this.$refs['video-whiteboard'].hide()
    },
  }
}
</script>

<style scoped>
    h4 {
        color: #DA3305;
        font-weight: 600;
        font-size: 21px;
        font-family: 'Montserrat SemiBold';
    }

    h5 {
        position: absolute;
        width: 100%;
        font-size: 16px;
    }

    .heading-8 {
        color: #da3305;
    }
	/* tab-2 */
	.tab-2 > div {
        border-right: solid 2px #dcdcdc;
	}

	.tab-2 img{
        margin-top: 5px;
	}

    .page-about .col-md-3 {
        border-bottom: solid 2px #dcdcdc;
    }

    /* Desktop */
    @media (min-width: 480px) {
        .tab-2 > div{
            height: 200px;
        }
        .tab-2 img {
            height: 50px;
            max-width: unset;
        }   

        .page-about .col-md-3 { 
            border-bottom: 0;
        }
        
        .text-container {
            top: 40%;
        }
    }

    /* tall */ 

    @media (min-height: 700px) {
        .tab-2 img {
            height: 40px;
        }
    }

    @media (min-height: 900px) {
        .tab-2 > div{
            height: 300px;
        }
        .tab-2 img {
            width: auto;
            height: 70px;
        }    
    }

    /* tall */ 
    @media (min-height: 1200px) {
        .tab-2 > div{
            height: 400px;
        }  
    }

    
    @media(min-width: 768px) and (max-width: 960px) {
        .tab-2 img {
            height: 60px;
        }
    }
</style>