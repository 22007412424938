<template>
    <div class="home fontsize" :class='fontsize'>
        
        <PageIntro />
        
        <About />

    </div>
</template>

<script>
// @ is an alias to /src
import PageIntro from '@/components/home/PageIntro.vue'
import About from '@/components/home/About.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
    name: 'Home',
    components: {
        PageIntro,    
        About
    },
    data(){
        return {
            
        }
    },
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'About Us',
      meta : [
        { property: 'og:title', content: 'title'},
        { property: 'og:type', content: 'article'},
      ]     
    },


    mixins: [dynamicFontSizeMixin]
}
</script>

<style>
    .modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: right;z-index:5;
        font-size: 20px;
    }

    /* Mobile */
    @media (max-width: 767px) {
        .hero{
            height: 100vh;
        }
        .tab img{
            width: 100vw;
        }
        .tab-2 > *{
            height: 50vh;
            overflow: hidden;
        }
    }

    /* Tablet */

    @media (max-width: 768px) {
     .tab-2 > *{
         height: 50vh;
         overflow: hidden;
     }
     .tab-2 .col-lg-3{
         border-bottom: solid 2px #dcdcdc;
     }
    }

    /* desktop */
    @media (min-width: 768px) {    
        .tab{
            height: 62vh;
        }

        .tab img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .tab > .row img{
            width: 33.33vw;
            height: 31vh;
        }
    }

    @media (min-width:992px){
        .tab-2 > * {
            height: 30vh;
        }
    }


    @media (min-height: 600px) and (max-width: 420px){
        .tab img{
            height: 100%;
        }
    }   


</style>