<template>
	<div class="nav">
		<section class="navigation row fixed-top" id="main-navigation">
				<div class="col-2 offset-1">
					<div class="logo bg-white">
						<router-link to="/" @click.native='closeNav'><img src="@/assets/logo.png" alt=""></router-link>
					</div>
				</div>
				<div class="col-7 nav-title">
					<span style=''>Stronger & Better Together <br class="t2">AIC Yearbook FY19-20</span>
				</div>
				<div class="col-2 nav-button">			
						<button class="navbar-toggler hamburger" 
									  type="button" 
									  v-b-toggle.collapse-1 
									  href="#"
						>
	          <font-awesome-icon :icon="(showCollapse1) ? ['fas','times'] : ['fas', 'bars']" class="icon"/>

					</button>
				</div>
		</section>

		<b-collapse class="menu fontsize" id="collapse-1" v-model='showCollapse1'>
			<div class="nav-menu navbar-collapse justify-content-start row mt-5">
				<div class="nav-menu-items align-self-center col-10 offset-1">
					<router-link to="/" class='link' v-b-toggle.collapse-1 >Home</router-link>					
					
					<hr>
					<span style="font-size: 18px;">Sustaining care through adversity</span>
					<ul class="list">
						<li>
							<router-link to="/covid" class='link' v-b-toggle.collapse-1>Support for our sector</router-link>
						</li>
						<li>
							<router-link to="/support-public" class='link' v-b-toggle.collapse-1>Support for seniors &amp; the public</router-link>
						</li>
					</ul>
					<hr>
					<span style="font-size: 18px;">Building a stronger care community</span>
					<ul class="list">
						<li>
							<router-link to="/support-seniors" class='link' v-b-toggle.collapse-1>Support for seniors, clients & caregivers</router-link>
						</li>
						<li>
							<router-link to="/support-partners" class='link' v-b-toggle.collapse-1>Support for partners</router-link>
						</li>
						<li>
							<router-link to="/looking-ahead" class='link' v-b-toggle.collapse-1>Looking ahead</router-link>
						</li>
					</ul>
					<hr>
					<router-link class="link" to="/one-aic-family" v-b-toggle.collapse-1>Together as #OneAICFamily</router-link>
					<hr>
					<router-link to="/our-organisation" class='link' v-b-toggle.collapse-1>Our Organisation</router-link>
					<hr>

					<router-link to="/our-leadership" class='link' v-b-toggle.collapse-1>Our Leadership</router-link>

					<hr>
					<router-link to="/contributors" class='link' v-b-toggle.collapse-1>Our Key Committees</router-link>
					<hr>
				</div>					
				<div class="nav-icons icons col-5 offset-6">
						<a href='https://www.facebook.com/AICSingapore/'>
            	<font-awesome-icon :icon="['fab', 'facebook-f']" 
            	:style="{'color': 'white', 'font-size': '20px', 'margin':'0 8px 2px'}"/>
          	</a>
          	<a href='https://www.instagram.com/aic_singapore/?hl=en'>
            	<font-awesome-icon :icon="['fab', 'instagram']" 
            	:style="{'color': 'white', 'font-size': '26px', 'margin':'0 8px'}"/>
          	</a>
          	<a href='https://www.youtube.com/user/aicsingapore'>
            	<font-awesome-icon :icon="['fab', 'youtube']" 
            	:style="{'color': 'white', 'font-size': '28px', 'margin':'0 8px'}"/>
						</a>
				</div>
			</div>
		</b-collapse>
	</div>
</template>

<script>
export default {
	name: 'Navigation',
	data(){
		return {
			showCollapse1:false
		};
	},


	methods:{
		toPageTop() {
			window.scrollTo(0,0);
		},

		closeNav() {
			if(this.showCollapse1) this.showCollapse1 = false;
		},
	},

	watch: {
		showCollapse1() {
			if(this.showCollapse1) {
				document.body.style.overflowY = 'hidden';
			}
			else {
				document.body.style.overflow = 'auto';
			}
		}
	}
}
</script>

<style scoped>
/* mobile first */



 .logo{
   min-width: 80px;
   text-align: center;
   height: 56px;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
   box-shadow: 4px 6px 15px -6px rgba(0,0,0,0.75);
   padding: 8px 6px 6px 6px;

 }

 .logo img{
   height: auto;
 }

 .collapse-1 {
 	margin-top:  -20px;
 }

.navigation .link {
	font-family: 'Frutiger Neue LT';
}
#collapse-1 {
	position: fixed;
 	overflow-y: scroll;
  top: 0; right: 0; bottom: 0; left: 0; 
}


#collapse-1 .link {
	font-size: 18px;
}

hr{
  margin: 10px 0;
}


li::before {
	content: "";
	border-color: transparent #f49c04;
	border-style: solid;
	border-width: 0.35em 0 0.35em 0.4em;
	display: block;
	height: 0;
	width: 0;
	left: -1em;
	top: 1em;
	position: relative;
}

.list a, .list a:hover{
	color: #f49c04;
	font-size: 162px;
}

.list {
	margin-left: 20px;
	margin-top:-10px;
}

.list li {
	margin-top: 5px;
 }

.list li a{
	font-size: 18px;
	line-height: 0.8;
}
.list li a:hover{
	font-size: 18px;
}



.menu{
	z-index: 2;
	text-align: left;
	top: 0;
	bottom: 0;
	position: absolute;
	transition: 0.3s;
}

.menu.collapsing{
	position: absolute;
}

.nav-menu{
	background-color: rgba(75, 75, 75, 1);
	height: 100vh;
}

.icons{
   display: inline-block;
   text-align: right;
   margin-bottom: 25px;
}

.hamburger {
	margin-top: -10px;	
}

.hamburger .icon{
	color: white; 
	font-size: 20px;
}

.hamburger{
	text-align: center;
}

.nav-title, .nav-button{
	display: flex;
  justify-content: center;
  align-items: center;
}

/*mobile */

@media (max-width: 370px){
	.logo {
		height: 90px;
	}

	.nav-title > span {
		margin-left: 65px !important;
		font-size: 12px;
	}
}

@media (max-width: 420px){
	.nav-title > span {
		font-size: 12px;
		margin-left: 80px;
	}
}

@media (max-width: 480px){
	.nav-title{
		font-size:13px;
		
	}
	.hamburger .icon{
		font-size:16px;	
	}

	.nav-title span {
		margin-left: 50px;
		position: fixed;
		margin-top: -6px;
	}

	.nav {
		height: 35px;
	}
	.navigation {
		height: 80px;
	}
	.logo {
		width: 110px;
		height: 85px;
	}
	#collapse-1 {
		margin-top: 25px;
	}
}



@media (min-width: 481px) {
	.navigation {
		height: 70px;
	}
	.logo {
		height: 85px;
	}
	.nav {
		height: 45px;
	}

	.nav-title > span {
		margin-left: 10%;
		margin-top: -15px;
	}	
}


 /* desktop */
 @media (min-width: 768px) {
   .nav-title{
   		left: -50px;
   }
   .nav-menu-items {
   	  margin-top: 50px;
   }

   img {
		max-width: 90%;
	}

	.logo {
		min-width: 130px;
		height: 90px;
	}

	.nav {
		height: 30px;
	}
	.navigation {
		height: 80px;
	}

	.nav-title > span {
		margin-top: -10px;
		text-align: center;
	}
 }





@media (min-width: 1025px) {
	.t2 {
		display: none;
	}

	img {
		max-width:85%;
	}

	.logo {
		min-width: 120px;
		height: 85px;
	}

	.nav {
		height: 20px;
	}
	.navigation {
		height: 70px;
	}

	.nav-title > span {
		margin-top: -10px;
		text-align: center;
		font-size: 26px;
		font-weight: 500;
	}
}

</style>