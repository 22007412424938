<template>
    <div id="app">
        <Navigation/>

        <transition name="fade">
            <router-view/>
        </transition>
        <FontSize />
        <Footer/>
    </div>
</template>

<script>
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/fonts/font.css';
import './assets/css/style.css';
import './assets/css/content.css';

import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import FontSize from '@/components/FontSize.vue'    



export default {
    components: {
        Navigation,
        Footer,
        FontSize
    },

    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'AIC Yearbook.sg',
      // all titles will be injected into this template
      titleTemplate: '%s | AIC Yearbook.sg',
    }

}


</script>


<style>
#app {
    color: #2c3e50;
}

.fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .4s;
}

.fade-enter-active {
    transition-delay: .4s;
}

.fade-enter, .fade-leave-active {
    opacity: 0
}
</style>
