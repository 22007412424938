import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
    fontsize: 3
 },
 getters: {
    size(state) {
        return "fontsize-" + state.fontsize 
    }
 },
 mutations: {
    decreaseSize(state) {
        if(state.fontsize == 1) return
        else {
            state.fontsize = state.fontsize - 1;
        } 
    },

    increaseSize(state) {
        if(state.fontsize == 5) return
        else {
            state.fontsize = state.fontsize + 1;
        }
    },

    reset(state) {
        state.fontsize = 3 
    }
 },
 actions: {}
});