<template>
	<section class="footer justify-content-center row" :class="isFixed()">
			<div class="col-12 col-md-7 footer-left">
				AIC Yearbook FY19 & FY20 (1 April 2019 to 31 March 2021)
			</div>

			<div class="col-12 col-md-5 footer-right">Agency for Integrated Care © 2021</div>		

	</section>
</template>

<script>
export default {
	name: 'Footer',
	methods: {
		isFixed() {
			if (this.$route.name =="Home" && document.documentElement.clientWidth > 380) {
				return 'fixed-bottom'; 			
			} else {
				return ''
			}
		}
	}
}
</script>

<style scoped>

.footer{
   bottom: 0;
   padding:  10px 0;


}
.footer-left {
	text-align: center;
	font-size: 10px;  
	padding: 4px 0;
	margin-top: 2px;
}
.footer-right {
	text-align: center;
	font-size: 12px;
	font-family: 'Frutiger Neue LT Bold', sans-serif;
	font-weight: 600;
	margin-top: 3px;
}




@media (min-width: 480px) and (min-height: 450px) {
   .footer{
   	 padding-top: 12px;
   	 padding-bottom: 8px;
   	 padding-left: 7.7%;
   	 padding-right: 7.7%;
   }
   .footer-left {
   	font-size: 14px;
   	margin-top: -2px;

   	text-align: left;
   }
   .footer-right {
   	font-size: 14px;
   	font-weight: 400;
   	text-align: right;
   }
}

</style>